
import { useContext } from "react";
import { DataContext } from "../Context";

function MarketChips() {
  const context = useContext(DataContext);

  const scrollMatchListToTop = () => {
    let matchList = document.getElementById("MatchList");
    if (matchList != null) {
      matchList.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  const selectMarket = (market) => {
    context?.setSelectedMarket(market);
    scrollMatchListToTop();
  }

  return (
    <div className="AppHeader-markets">
      {
        context?.getAvailableMarkets().map((market, market_index) =>
          <div id={`market-chip-${market?.id}`}
            className={`AppHeader-markets-chip ${context?.selectedMarket?.id === market?.id ? "selected" : ""}`}
            onClick={() => { selectMarket(market) }}
            key={market_index}>
            {market?.title}
          </div>
        )
      }
    </div>
  );
}

export default MarketChips;