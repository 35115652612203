
import './App.css';
import { useContext, useEffect, useState } from 'react';
import { DataContext } from './Context';
import { AUTHENTICATED_KEY, USER_UUID_KEY } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { Outlet } from 'react-router-dom';

function App() {
  const context = useContext(DataContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const correctPassword = 'flashbet!123'; // Replace with your actual password

  useEffect(() => {
    const authenticateUser = () => {
      // Check if user is already authenticated
      if (sessionStorage.getItem(AUTHENTICATED_KEY) === 'true') {
        setIsAuthenticated(true);
        return;
      }

      // Prompt user for password
      let userPassword = window.prompt('Please enter the password:');
      if (userPassword === correctPassword) {
        sessionStorage.setItem(AUTHENTICATED_KEY, 'true');
        context?.updateWallet(5000);
        setIsAuthenticated(true);
      } else {
        alert('Incorrect password. Please try again.');
        window.location.reload(); // Reloads the page to prompt again
      }
    };

    const logInUser = () => {
      let userUuid = localStorage.getItem(USER_UUID_KEY)

      if(!userUuid) {
        const uuid = uuidv4();
        localStorage.setItem(USER_UUID_KEY, uuid);
        userUuid = uuid;
      }

      context.setUserUuid(userUuid);
    }

    authenticateUser();
    logInUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated && context?.wallet <= 0) {
      alert('Wallet is empty. Refresh the page to get more funds');
      sessionStorage.removeItem(AUTHENTICATED_KEY);
      setIsAuthenticated(false);
    }
  }, [context?.wallet]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
