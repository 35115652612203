import FLASH_ICON from '../assets/images/flash-icon.svg';
import OUTCOME_LOSS from '../assets/images/outcome-loss.svg';
import OUTCOME_WIN from '../assets/images/outcome-win.svg';
import { useContext } from 'react';
import { DataContext } from '../Context';
import formatTime from '../utils/format_time';
import findLastWhere from '../utils/find_last_where';
import Loader from './Loader';
import ElectricLoader from './ElectricLoader';


function SimulationMatch({ event, selection, liveScore, number }) {
    const context = useContext(DataContext);

    const market = event?.markets?.find(market => market?.id === selection?.market_id);
    const outcome = market?.outcomes?.find(outcome => outcome?.id === selection?.outcome_id);

    const getFirstHalfResult = () => {
        if (liveScore?.results == null) {
            return "0-0"
        }
        return findLastWhere(liveScore?.results, (result) => result?.period === 1);
    }

    const getCurrentResult = () => {
        return liveScore?.results?.[liveScore?.results?.length - 1];
    }

    const getFirstHalfScore = () => {
        return getFirstHalfResult()?.currentResult ?? "0-0"
    }

    const getCurrentScore = () => {
        return getCurrentResult()?.currentResult ?? "0-0"
    }

    const isBetHit = () => {
        let roundResult = context?.roundResult;

        if (roundResult != null) {
            let eventResult = roundResult?.[0]?.events?.find(roundEvent => roundEvent?.eventId === event?.eventId);
            if (eventResult != null) {
                return eventResult?.markets?.[0]?.outcomes?.[0]?.hit === true;
            }
        }

        return null;
    }

    return (
        <>
            <tr className='SimulationMatch-margin'>
            </tr>
            <tr>
                <td className='SimulationMatch-outcome-icon' rowSpan={2}>
                    {isBetHit() == null ? <ElectricLoader elementId={`electric-loader-outcome__${number}`} radius={12} />
                        : isBetHit() === true ? <img className='outcome-icon' src={OUTCOME_WIN} />
                            : <img className='outcome-icon' src={OUTCOME_LOSS} />
                    }
                </td>
                <td className='SimulationMatch-team-name'>
                    {event?.homeTeamName}
                </td>
                <td className={`SimulationMatch-score SimulationMatch-first-half-score ${getFirstHalfScore()?.split("-")?.[0] === '0' ? "no-animation" : ""}`} key={`home-1-${getFirstHalfScore()?.split("-")?.[0]}`}>
                    {getFirstHalfScore()?.split("-")?.[0]}
                </td>
                <td className={`SimulationMatch-score SimulationMatch-current-score ${getCurrentScore()?.split("-")?.[0] === '0' ? "no-animation" : ""}`} key={`home-2-${getCurrentScore()?.split("-")?.[0]}`}>
                    {getCurrentScore()?.split("-")?.[0]}
                </td>
                <td className='SimulationMatch-flash-icon' rowSpan={2}>
                    <img className='flash-icon' src={FLASH_ICON} />
                </td>
            </tr>
            <tr>
                <td className='SimulationMatch-team-name'>
                    {event?.awayTeamName}
                </td>
                <td className={`SimulationMatch-score SimulationMatch-first-half-score ${getFirstHalfScore()?.split("-")?.[1] === '0' ? "no-animation" : ""}`} key={`away-1-${getFirstHalfScore()?.split("-")?.[1]}`}>
                    {getFirstHalfScore()?.split("-")?.[1]}
                </td>
                <td className={`SimulationMatch-score SimulationMatch-current-score ${getCurrentScore()?.split("-")?.[1] === '0' ? "no-animation" : ""}`} key={`away-2-${getCurrentScore()?.split("-")?.[1]}`}>
                    {getCurrentScore()?.split("-")?.[1]}
                </td>
            </tr>
            <tr>
                <td className='SimulationMatch-number'>
                    #{number}
                </td>
                <td colSpan={2} className='SimulationMatch-market'>
                    {market?.name}
                    {
                        market?.specifier?.length > 0 ? <span> ({market?.specifier?.replace("total=", "")})</span> : null
                    }
                </td>
                <td className='SimulationMatch-outcome'>
                    {outcome?.desc}
                </td>
                <td className='SimulationMatch-odd'>
                    {outcome?.odds}
                </td>
            </tr>
            <tr className='SimulationMatch-margin'>
            </tr>
        </>
    );
}

export default SimulationMatch;
