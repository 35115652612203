import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "../Context";
import ROUND_WIN from '../assets/images/round-win.svg';
import ROUND_LOSS from '../assets/images/round-loss.svg';
import WIN_FLASH from '../assets/images/win-flash.gif';
import CLOSE from '../assets/images/close.svg';
import { ROUND_RESULT_DIALOG_CONTENT_ID } from "../constants";
import ElectricLine from "./ElectricLine";
import ElectricSpark from "./ElectricSpark";

const { REACT_APP_FLASHBET_BACKEND_BASE_URL } = process.env;

function RoundResultDialog() {
    const context = useContext(DataContext);

    // For now we assume one ticket, but after autobet is added this logic must be changed
    const getTicketId = () => {
        return context?.simulationTicketIds?.[0];
    }

    const isWin = () => {
        return context?.roundResult?.every(ticket => ticket?.totalReturn > 0);
    }

    const getTotalReturn = () => {
        let totalReturn = 0;
        for (let ticket of (context?.roundResult ?? [])) {
            totalReturn += (ticket?.totalReturn ?? 0)
        }

        return totalReturn;
    }

    return (
        <div className="RoundResultDialog">
            <div id={ROUND_RESULT_DIALOG_CONTENT_ID} className={`RoundResultDialogContent ${isWin() ? `RoundResultDialogContent-animated` : ``}`}>
                <div className="RoundResultDialogContent-overflow-hidden">
                    <div className="RoundResultDialogContent-close" onClick={() => { context?.setShowRoundResultDialog(false) }}>
                        <img src={CLOSE} />
                    </div>
                    <div className="RoundResultDialogContent-image">
                        <img src={isWin() ? ROUND_WIN : ROUND_LOSS} />
                    </div>
                    <div className="RoundResultDialogContent-title">
                        {isWin() ? "WIN" : "LOSS"}
                    </div>
                    {
                        isWin() ? <div className="RoundResultDialogContent-total-return">
                            {getTotalReturn()?.toFixed(2)} EUR
                        </div> : null
                    }
                    <div className="RoundResultDialogContent-label-amount">
                        <p>Stake</p>
                        <p>{context?.getEffectiveStake()?.toFixed(2)} EUR</p>
                    </div>
                    <div className="RoundResultDialogContent-label-amount">
                        <p>Ticket ID</p>
                        <p>{getTicketId()}</p>
                    </div>
                    <div className={`RoundResultDialogContent-close-btn`} onClick={() => { context?.setShowRoundResultDialog(false) }}>
                        ZATVORI
                    </div>
                </div>
                {isWin() ? <>
                    <div className="RoundResultDialog-shine-wrapper"><div className="RoundResultDialog-shine" ></div></div>
                    <ElectricLine position={"top"} elementId={"electric-line-top"} />
                    <ElectricLine position={"bottom"} elementId={"electric-line-bottom"} />
                    <ElectricSpark position={"top-left"} elementId={"electric-spark-top-left"} />
                    <ElectricSpark position={"top-right"} elementId={"electric-spark-top-right"} />
                    <ElectricSpark position={"bottom-left"} elementId={"electric-spark-bottom-left"} />
                    <ElectricSpark position={"bottom-right"} elementId={"electric-spark-bottom-right"} />
                </> : null
                }
            </div>
            {isWin() ? <div className="RoundResultDialog-win-flash"><img src={WIN_FLASH} /></div> : null}
            
        </div>
    );
}

export default RoundResultDialog;
