import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "../Context";
import SIMULATION_COUNTDOWN from '../assets/images/simulation-countdown.gif';
import SIMULATION_COUNTDOWN_TRANSPARENT from '../assets/images/simulation-countdown-transparent.gif';
import SIMULATION_START from '../assets/images/simulation.start.gif';
import SIMULATION_START_TRANSPARENT from '../assets/images/simulation-start-transparent.gif';

const { REACT_APP_FLASHBET_BACKEND_BASE_URL } = process.env;

function SimulationCountdown() {
    const context = useContext(DataContext);

    return (
        <div className="SimulationCountdown">
            {/*
            <img className="SimulationCountdown-background" src={context?.simulationCountdown > 0 ? SIMULATION_COUNTDOWN : SIMULATION_START} />
            <img className="SimulationCountdown-background-transparent" src={context?.simulationCountdown > 0 ? SIMULATION_COUNTDOWN_TRANSPARENT : SIMULATION_START_TRANSPARENT} />
            */}
            {context?.simulationCountdown >0 ? <div className="SimulationCountdown-countdown" key={`countdown-${context?.simulationCountdown}`}>{context?.simulationCountdown}</div> : null}
        </div>
    );
}

export default SimulationCountdown;
