import React, { useRef } from 'react';
import { useState, createContext, useEffect } from 'react';
import EVENTS_RESPONSE from './assets/sample_responses/events';
import axios from './axios';
import useDebounce from './utils/use_debounce';
import getRandomIntInclusive from './utils/get_random_int_inclusive';
import { EVENTS_TAB_LIVE, MAX_RESULT_DISPLAY_DELAY, MIN_RESULT_DISPLAY_DELAY, QUERY_KEY_EVENTS_TAB, QUERY_KEY_SELECTED_EVENT_ID, QUERY_KEY_SELECTED_MARKET_ID, QUERY_KEY_SHOW_TICKET, SIMULATION_ROUND_ID_KEY, SIMULATION_TICKET_IDS_KEY, sport_id, WALLET_KEY } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { io } from "socket.io-client";
import useThrottle from './utils/use_throttle';
import { useSearchParams } from 'react-router-dom';

const {
    REACT_APP_FLASHBET_BACKEND_BASE_URL,
    REACT_APP_FLASHBET_BACKEND_WEBSOCKET_URL,
} = process.env;


export const defaultValidationData = {
    "totalOdds": 0.00,
    "bonusPercent": 0.00,
    "bonusAmount": 0.00,
    "winAmount": 0.00,
    "winTaxAmount": 0.00,
    "stakeTaxAmount": 0.00,
    "winAmountAfterTax": 0.00,
    "errors": []
}

const testLiveScoresSingle = {
    "240813094424tic000000002__sr:match:51630029026036682": {
        "eventId": "sr:match:51630029026036682",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 2
            },
            {
                "awayTeamScore": true,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 4
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 5
            }
        ],
        "ticketId": "240813094424tic000000002"
    },
}

const testLiveScores = {
    "240813094424tic000000002__sr:match:51630029026036682": {
        "eventId": "sr:match:51630029026036682",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 2
            },
            {
                "awayTeamScore": true,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 4
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-1",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 5
            }
        ],
        "ticketId": "240813094424tic000000002"
    },
    "240813094424tic000000002__sr:match:47244711036479898": {
        "eventId": "sr:match:47244711036479898",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 2
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 2,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 4
            }
        ],
        "ticketId": "240813094424tic000000002"
    },
    "240813094424tic000000002__sr:match:51630031072317130": {
        "eventId": "sr:match:51630031072317130",
        "results": [
            {
                "awayTeamScore": false,
                "currentResult": "1-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 1
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": true,
                "message": "GOAL",
                "period": 1,
                "sortNumber": 2
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": false,
                "message": "HT",
                "period": 2,
                "sortNumber": 3
            },
            {
                "awayTeamScore": false,
                "currentResult": "2-0",
                "homeTeamScore": false,
                "message": "FT",
                "period": 2,
                "sortNumber": 4
            }
        ],
        "ticketId": "240813094424tic000000002"
    }
}

const testRoundResult = [
    {
        "createTime": 1723535065280,
        "events": [
            {
                "awayTeamName": "KS Lechia Gdansk SRL",
                "awayTeamScore": "1",
                "eventId": "sr:match:51630029026036682",
                "homeTeamName": "1 FC Slovacko (Srl)",
                "homeTeamScore": "2",
                "live": false,
                "markets": [
                    {
                        "marketId": "1",
                        "outcomes": [
                            {
                                "desc": "Home",
                                "hit": true,
                                "odds": "2.01",
                                "outcomeId": "1"
                            }
                        ],
                        "title": "1X2"
                    }
                ],
                "resultSequence": "AABH"
            },
            {
                "awayTeamName": "Sheffield Utd SRL",
                "awayTeamScore": "0",
                "eventId": "sr:match:51630031072317130",
                "homeTeamName": "Genoa SRL",
                "homeTeamScore": "2",
                "live": false,
                "markets": [
                    {
                        "marketId": "1",
                        "outcomes": [
                            {
                                "desc": "Draw",
                                "hit": false,
                                "odds": "3.13",
                                "outcomeId": "2"
                            }
                        ],
                        "title": "1X2"
                    }
                ],
                "resultSequence": "AAH"
            },
            {
                "awayTeamName": "Cheongju FC",
                "awayTeamScore": "0",
                "eventId": "sr:match:47244711036479898",
                "homeTeamName": "Busan I Park",
                "homeTeamScore": "2",
                "live": false,
                "markets": [
                    {
                        "marketId": "1",
                        "outcomes": [
                            {
                                "desc": "Away",
                                "hit": false,
                                "odds": "3.4",
                                "outcomeId": "3"
                            }
                        ],
                        "title": "1X2"
                    }
                ],
                "resultSequence": "AHA"
            }
        ],
        "sportId": "sr:sport:1",
        "stake": 1,
        "taxOnWinning": 0,
        "ticketId": "240813094424tic000000002",
        "ticketNumber": "60060960",
        "totalReturn": 0,
        "type": "multiple"
    }
];

// jsonName: altName
export const altOutcomeNameMap = {
    "Home": "1",
    "Draw": "X",
    "None": "X",
    "Away": "2",
    "Home or Draw": "1X",
    "Home or Away": "12",
    "Draw or Away": "X2",
    "Home (0:1)": "1 (0:1)",
    "Draw (0:1)": "X (0:1)",
    "Away (0:1)": "2 (0:1)",
    "Home (0:2)": "1 (0:2)",
    "Draw (0:2)": "X (0:2)",
    "Away (0:2)": "2 (0:2)",
    "Home (1:0)": "1 (1:0)",
    "Draw (1:0)": "X (1:0)",
    "Away (1:0)": "2 (1:0)",
    "Home (2:0)": "1 (2:0)",
    "Draw (2:0)": "X (2:0)",
    "Away (2:0)": "2 (2:0)",
    "Home & Under": "1 Under",
    "Home & Over": "1 Over",
    "Draw & Under": "X Under",
    "Draw & Over": "X Over",
    "Away & Under": "2 Under",
    "Away & Over": "2 Over",
    "Home & yes": "1 Yes",
    "Home & no": "1 No",
    "Draw & yes": "X Yes",
    "Draw & no": "X No",
    "Away & yes": "2 Yes",
    "Away & no": "2 No",
}

const marketIdToRealSportMarketIdMap = {
    "1": "1",
    "2": "18",
    "3": "29",
    "4": "10",
    "5": "14",
    "6": "30",
    "7": "8",
    "8": "9",
    "9": "19",
    "10": "20",
    "11": "60",
    "12": "68",
    "13": "63",
    "14": "62",
    "15": "83",
    "16": "90",
    "17": "85",
    "18": "84",
    "19": "37",
    "20": "35"
};

const realSportMarketIdToMarketId = {};

for (let [key, value] of Object.entries(marketIdToRealSportMarketIdMap)) {
    realSportMarketIdToMarketId[value] = key;
}


const useProviderFunctions = () => {
    let initSelections = [];

    try {
        initSelections = JSON.parse(sessionStorage.getItem('flashbet-selections') ?? '[]');
        // console.log("Init selections", initSelections)
    } catch (error) {
        // console.log("Init selections error", error)
    }

    let initWallet = [];

    try {
        initWallet = parseFloat(sessionStorage?.getItem(WALLET_KEY) ?? '0');
        // console.log("Init wallet", initWallet)
    } catch (error) {
        // console.log("Init wallet error", error)
    }

    const socketRef = useRef(null);
    const roundResultRef = useRef(null);
    const simulationTicketIdsRef = useRef(null);
    const selectionCountRef = useRef(0);
    const liveScoreDisplayQueueRef = useRef([]);
    const delayRef = useRef(MAX_RESULT_DISPLAY_DELAY); // Modify this value to adjust the delay
    const currentTimer = useRef(null); // Ref for the timer of the timeout
    const messageStartTime = useRef(null); // Start time of the current display message
    const currentDisplayScoreRef = useRef(null);
    const halfTimeExistsRef = useRef(false);
    const isReplayRef = useRef(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [wallet, setWallet] = useState(initWallet);
    const [sessionId, setSessionId] = useState(null);
    const [events, setEvents] = useState([]);
    const [eventsTab, setEventsTab] = useState(EVENTS_TAB_LIVE);
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [selectedEventList, setSelectedEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selections, setSelections] = useState(initSelections);
    const [showTicket, setShowTicket] = useState(false);
    const [stake, setStake] = useState(parseFloat(sessionStorage.getItem('flashbet-stake') ?? '1'));
    const [simulationCountdown, setSimulationCountdown] = useState(null);
    const [simulationTicketIds, setSimulationTicketIds] = useState(null);
    const [simulationRoundId, setSimulationRoundId] = useState(null);
    const [generalConfig, setGeneralConfig] = useState(null);
    const [sports, setSports] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [validationData, setValidationData] = useState(defaultValidationData);
    const [liveScores, setLiveScores] = useState({});
    const [currentDisplayScore, setCurrentDisplayScore] = useState(null);
    const [roundResult, setRoundResult] = useState(null);
    const [showRoundResultDialog, setShowRoundResultDialog] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(true);
    const [loadingGeneralConfig, setLoadingGeneralConfig] = useState(true);
    const [loadingValidation, setLoadingValidation] = useState(true);
    const [loadingPayin, setLoadingPayin] = useState(false);
    const [loadingRoundResult, setLoadingRoundResult] = useState(false);
    const userUuid = useRef(null)

    useEffect(() => {
        getEvents();
        getGeneralConfig();
        connectSocket();
        getActiveRound();

        return () => {
            if (socketRef.current != null) {
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        let searchSelectedEventsTab = searchParams.get(QUERY_KEY_EVENTS_TAB) ?? EVENTS_TAB_LIVE;
        if (searchSelectedEventsTab !== eventsTab) {
            setEventsTab(searchSelectedEventsTab);
        }

        let searchSelectedMarketId = searchParams.get(QUERY_KEY_SELECTED_MARKET_ID);
        if (searchSelectedMarketId !== (selectedMarket?.id ?? null)) {
            let market = markets?.[0];
            if (searchSelectedMarketId != null) {
                market = markets?.find(market => market?.id === searchSelectedMarketId);

                let marketChip = document.getElementById(`market-chip-${searchSelectedMarketId}`);
                if (marketChip != null) {
                    marketChip.scrollIntoView({ behavior: 'smooth' });
                }
            }
            setSelectedMarket(structuredClone(market));
        }

        let searchSelectedEventId = searchParams.get(QUERY_KEY_SELECTED_EVENT_ID);
        if (searchSelectedEventId !== (selectedEvent?.eventId ?? null)) {
            let event = null;
            if (searchSelectedEventId != null) {
                event = events?.find(event => event?.eventId === searchSelectedEventId);
            }
            setSelectedEvent(event);
        }

        let searchShowTicket = JSON.parse(searchParams.get(QUERY_KEY_SHOW_TICKET) ?? 'false');
        if (searchShowTicket !== showTicket) {
            setShowTicket(searchShowTicket);
        }
    }, [
        searchParams,
        setSearchParams,
        events,
    ]);

    useEffect(() => {
        debouncedAddQueryParam(QUERY_KEY_EVENTS_TAB, eventsTab);
        let marketChip = document.getElementById(`market-chip-${selectedMarket?.id}`);
        if (marketChip != null) {
            marketChip.scrollIntoView({ behavior: 'smooth' });
        }
    }, [eventsTab]);

    useEffect(() => {
        if (events?.length > 0 && markets?.length > 0) {
            debouncedAddQueryParam(QUERY_KEY_SELECTED_MARKET_ID, selectedMarket?.id);
        }
    }, [selectedMarket]);

    useEffect(() => {
        if (events?.length > 0) {
            debouncedAddQueryParam(QUERY_KEY_SELECTED_EVENT_ID, selectedEvent?.eventId);
        }
    }, [selectedEvent]);

    useEffect(() => {
        debouncedAddQueryParam(QUERY_KEY_SHOW_TICKET, showTicket);
    }, [showTicket]);

    useEffect(() => {
        if (getSelectedEvents()?.length === 0) {
            setSelectedMarket(selectedMarket ?? getAvailableMarkets()?.[0]);
        }
    }, [eventsTab]);

    useEffect(() => {
        selectionCountRef.current = selections?.length ?? 0;
        sessionStorage.setItem('flashbet-selections', JSON.stringify(selections));
    }, [selections]);

    useEffect(() => {
        if (selectedMarket == null || loadingEvents || loadingGeneralConfig) {
            return;
        }
        console.log("SELECTED MARKET CHANGE", selectedMarket);
        setSelectedEventList(getSelectedEvents());
    }, [eventsTab, selectedMarket, loadingEvents, loadingGeneralConfig]);

    useEffect(() => {
        console.log(liveScores);
    }, [liveScores]);

    // This is to make sure the round persists if the user leaves the page without getting the result
    useEffect(() => {
        if (simulationRoundId != null) {
            sessionStorage.setItem(SIMULATION_ROUND_ID_KEY, simulationRoundId);
        }
        if (simulationTicketIds?.length > 0) {
            sessionStorage.setItem(SIMULATION_TICKET_IDS_KEY, JSON.stringify(simulationTicketIds));
        }
    }, [simulationRoundId, simulationTicketIds]);

    useEffect(() => {
        if (roundResult != null) {
            sessionStorage.removeItem(SIMULATION_ROUND_ID_KEY);
            sessionStorage.removeItem(SIMULATION_TICKET_IDS_KEY);
        }
    }, [roundResult]);

    /*
    // Throttled function to handle live score display
    const processQueue = useThrottle(() => {
        console.log("CURRENT DISPLAY", liveScoreDisplayQueueRef.current?.[0]);
        setCurrentDisplayScore(liveScoreDisplayQueueRef.current?.[0]);
        liveScoreDisplayQueueRef.current.shift();
        debouncedClearCurrentDisplay();
    }, Math.max(delayRef.current / (liveScoreDisplayQueueRef.current?.length > 0 ? liveScoreDisplayQueueRef.current?.length : 1), MIN_RESULT_DISPLAY_DELAY), [currentDisplayScore]);

    const clearCurrentDisplay = () => {
        setCurrentDisplayScore(null);
    }

    const debouncedClearCurrentDisplay = useDebounce(clearCurrentDisplay, delayRef.current, []);

    useEffect(() => {
        if (liveScoreDisplayQueueRef.current?.length > 1) {
            processQueue();
        }
    }, [currentDisplayScore, processQueue, liveScoreDisplayQueueRef.current]);

    */

    const showNextDisplayScore = () => {
        if (currentDisplayScore == null && liveScoreDisplayQueueRef.current.length > 0) {
            const nextDisplayScore = liveScoreDisplayQueueRef.current.shift();  // Remove the next message from the queue
            setCurrentDisplayScore(nextDisplayScore);
            messageStartTime.current = Date.now();  // Record start time for the message

            // Set a timer to remove the message after the display time
            clearTimeout(currentTimer.current);  // Clear the existing timeout
            currentTimer.current = setTimeout(() => {
                setCurrentDisplayScore(null);
                const newDelay = Math.max(MIN_RESULT_DISPLAY_DELAY, MAX_RESULT_DISPLAY_DELAY / (liveScoreDisplayQueueRef.current.length + 1));
                delayRef.current = newDelay;
                showNextDisplayScore();
            }, delayRef.current);
        }
    };

    // Recalculate display time if new messages arrive while a message is being displayed
    useEffect(() => {
        if (currentDisplayScore != null && liveScoreDisplayQueueRef.current.length > 0) {
            const newDelay = Math.max(MIN_RESULT_DISPLAY_DELAY, MAX_RESULT_DISPLAY_DELAY / ((liveScoreDisplayQueueRef.current.length + 2) / 2));
            const timeElapsed = Date.now() - messageStartTime.current;

            if (timeElapsed > newDelay) {
                delayRef.current = newDelay;
                showNextDisplayScore(); // immediately change to next message if the current one has already been shown too long for the recalculated delay
            }
        }
    }, [liveScoreDisplayQueueRef.current.length, currentDisplayScore]);  // Track changes in queue length and current message

    // Recalculate display time if new messages arrive while a message is being displayed
    useEffect(() => {
        currentDisplayScoreRef.current = currentDisplayScore;
    }, [currentDisplayScore]);  // Track changes in queue length and current message


    const addQueryParam = (key, value) => {
        if (value == null) {
            return removeQueryParam(key);
        }
        setSearchParams({ ...Object.fromEntries(searchParams), [key]: value });
    }

    const removeQueryParam = (key) => {
        searchParams.delete(key);
        setSearchParams(searchParams);
    }

    const debouncedAddQueryParam = useDebounce(addQueryParam, 200);
    const debouncedRemoveQueryParam = useDebounce(removeQueryParam, 200);

    const getActiveRound = () => {
        let sessionSimulationRoundId = sessionStorage.getItem(SIMULATION_ROUND_ID_KEY);
        if (sessionSimulationRoundId != null) {
            setSimulationRoundId(sessionSimulationRoundId);

            try {
                setSimulationTicketIds(JSON.parse(sessionStorage.getItem(SIMULATION_TICKET_IDS_KEY)));
            } catch (error) {
                console.log("Error setting simulation ticket ids from sessionStorage", error);
            }

            skipSimulation(null, sessionSimulationRoundId);
        }
    }

    const updateWallet = (amount) => {
        sessionStorage.setItem(WALLET_KEY, amount);
        setWallet(amount);
    }

    const walletHasSufficientAmount = (requiredAmount) => {
        return wallet >= requiredAmount;
    }

    const adjustWallet = (amount) => {
        updateWallet(wallet + amount);
    }

    const addWallet = (amount) => {
        adjustWallet(amount);
    }

    const reduceWallet = (amount) => {
        adjustWallet(-amount);
    }

    const getEvents = () => {
        setLoadingEvents(true);
        setTimeout(() => {
            let parsedEvents = [];
            for (let league of EVENTS_RESPONSE) {
                parsedEvents.push(...league.events?.map(event => ({ ...event, league_name: league?.name })));
            }

            for (let event of parsedEvents) {
                for (let market of event?.markets) {
                    for (let outcome of market?.outcomes) {
                        if (altOutcomeNameMap[outcome?.desc] != null) {
                            outcome.desc = altOutcomeNameMap[outcome?.desc] ?? outcome?.desc;
                        }
                        outcome.desc = outcome?.desc?.replace(/(Over|Under)\s\d+(\.\d+)?/g, '$1')?.trim();
                        if (altOutcomeNameMap[outcome?.desc] != null) {
                            outcome.desc = altOutcomeNameMap[outcome?.desc] ?? outcome?.desc;
                        }
                    }
                }
            }

            setEvents(parsedEvents);
            setLoadingEvents(false);
        }, 1000);
    }

    const getRoundResult = () => roundResultRef.current;
    const getTicketId = () => simulationTicketIdsRef.current?.[0];

    const connectSocket = () => {
        let previousSessionId = sessionStorage.getItem("session_id");
        let currentSessionId;

        if (previousSessionId == null) {
            const session_id = uuidv4();
            sessionStorage.setItem("session_id", session_id);
            setSessionId(session_id);
            currentSessionId = session_id;
        } else {
            setSessionId(previousSessionId);
            currentSessionId = previousSessionId;
        }

        if (socketRef.current == null) {
            let websocketUrl = REACT_APP_FLASHBET_BACKEND_BASE_URL?.includes("localhost") ? REACT_APP_FLASHBET_BACKEND_BASE_URL?.replace("8080", "8081") : REACT_APP_FLASHBET_BACKEND_BASE_URL;
            socketRef.current = io(websocketUrl, {
                path: '/websocket',
                query: {
                    "Session-Id": currentSessionId
                },
                withCredentials: true,
                autoConnect: false,
                transports: ["websocket", "polling", "flashsocket"], // Ensure transports are correctly set
            })

            socketRef.current.on("live-score", (liveScoreData) => {
                // console.log("LIVE SCORE", liveScoreData);
                console.log("LIVE SCORE", getTicketId());

                let liveScoreJSON = JSON.parse(liveScoreData);

                let eventId = liveScoreJSON?.eventId;
                let ticketId = liveScoreJSON?.ticketId;

                setLiveScores(prevLiveScores => {
                    const newLiveScores = {
                        ...prevLiveScores,
                        [`${ticketId}__${eventId}`]: liveScoreJSON
                    };
                    return newLiveScores;
                });

                let liveScoreDisplayJSON = structuredClone(liveScoreJSON);
                liveScoreDisplayJSON.currentResult = liveScoreDisplayJSON?.results?.[liveScoreDisplayJSON?.results?.length - 1];
                delete liveScoreDisplayJSON.results

                let liveScoreExists = liveScoreDisplayQueueRef.current?.find(score => JSON.stringify(score) === JSON.stringify(liveScoreDisplayJSON)) != null;
                let isHalfTime = liveScoreDisplayJSON?.currentResult?.message === "HT";
                let isFullTime = liveScoreDisplayJSON?.currentResult?.message === "FT";

                if (!liveScoreExists && (!halfTimeExistsRef.current || !isHalfTime) && !isFullTime) {
                    if (isHalfTime && selectionCountRef.current > 1) {
                        halfTimeExistsRef.current = true;
                        liveScoreDisplayJSON.currentResult.messageOnly = true;
                    }

                    // Add new live score to the display queue
                    liveScoreDisplayQueueRef.current?.push(liveScoreDisplayJSON);

                    // Adjust display time dynamically
                    const newDelay = Math.max(MIN_RESULT_DISPLAY_DELAY, MAX_RESULT_DISPLAY_DELAY / (liveScoreDisplayQueueRef.current.length + 1));
                    delayRef.current = newDelay;

                    // If there's no current message, show the next message
                    if (currentDisplayScoreRef.current == null && liveScoreDisplayQueueRef.current.length === 1) {
                        showNextDisplayScore();
                    }
                }
            })

            socketRef.current.on("round-end", (roundEndData) => {
                if (getRoundResult() != null) {
                    return;
                }
                console.log("ROUND END", roundEndData);

                let roundEndJSON = JSON.parse(roundEndData);
                console.log(getTicketId());

                if (roundEndJSON?.[0]?.ticketId !== getTicketId()) {
                    return;
                }

                console.log("SETTING ROUND RESULT");
                updateRoundResult(roundEndJSON);
                setShowRoundResultDialog(true);
            })

            socketRef.current.on('disconnect', () => {
                console.log('Disconnected from WebSocket');
                socketRef.current = null;
                connectSocket();
            });

            socketRef?.current?.connect();
        }
    }

    useEffect(() => {
        setLoadingValidation(true);
        debouncedValidateSelections();
    }, [selections, stake, events]);

    useEffect(() => {
        sessionStorage.setItem('flashbet-stake', stake);
    }, [stake]);

    const getGeneralConfig = async () => {
        setLoadingGeneralConfig(true);

        let response = await axios.get(`/init`)
        console.log(response?.data);

        let generalConfig = response?.data?.generalConfig;
        let sports = response?.data?.sports;
        let markets = response?.data?.markets;

        // Transform the markets to contain legacy realSportMarketId (because i can't be fucked with this anymore)
        for (let market of markets) {
            market.realSportMarketId = marketIdToRealSportMarketIdMap[market?.id];
        }

        setGeneralConfig(generalConfig);
        setSports(sports);
        setMarkets(markets);
        setSelectedMarket(selectedMarket ?? structuredClone(markets?.[0]))
        setStake(Math.min(Math.max(stake, generalConfig?.minStake), generalConfig?.maxStake));

        setLoadingGeneralConfig(false);
    }

    const validateSelections = async () => {
        if (!(selections?.length > 0 && events?.length > 0 && markets?.length > 0)) {
            setValidationData(defaultValidationData);
            setLoadingValidation(false);
            return;
        }

        setLoadingValidation(true);
        let validationPayload = createTicket({ validationOnly: true });

        console.log("VALIDATION", validationPayload);

        let response = null;

        if (validationPayload?.selections?.every(selection => selection?.eventId != null)) {
            try {
                response = await axios.post(`/v1/ticket/validate`, validationPayload, {
                    headers: {
                        "User-Id": userUuid.current,
                        "OperId": "100",
                        "Authorization": "",
                        "Platform": "android",
                    }
                });
            } catch (error) {
                console.log("VALIDATION ERROR", error);
            }
        }

        console.log("VALIDATION RESPONSE", response);

        setValidationData(response?.data);
        setLoadingValidation(false);
    }

    const debouncedValidateSelections = useDebounce(validateSelections, 700, [selections]);



    const getEffectiveStake = () => {
        return Number.isNaN(stake) ? 0 : stake ?? 0;
    }

    const getMinimumStake = () => {
        return Math.max(generalConfig?.minStake, selections?.length * generalConfig?.minStakePerCombination);
    }

    const createTicketSelections = () => {
        let ticketSelections = selections?.map(selection => {
            const event = structuredClone(events?.find(event => event?.eventId === selection?.event_id));
            const market = structuredClone(event?.markets?.find(market => market?.id === selection?.market_id));
            const marketId = realSportMarketIdToMarketId[market?.id];
            const sportId = sports?.[0]?.id;
            const specifier = selection?.specifier == null ? null : `${selection?.specifier}`;
            const outcome = structuredClone(market?.outcomes?.find(outcome => outcome?.id === selection?.outcome_id));
            const odds = parseFloat(outcome?.odds);

            return {
                "eventId": `${event?.eventId}`,
                "isLive": event?.status === 1,
                "matchStatus": event?.matchStatus,
                "homeTeamName": event?.homeTeamName,
                "awayTeamName": event?.awayTeamName,
                "markets": [
                    {
                        "marketId": `${marketId}`,
                        "sportId": `${sportId}`,
                        "specifier": specifier,
                        "outcomes": [
                            {
                                "id": `${outcome?.id}`,
                                "odds": `${odds}`,
                            }
                        ]
                    }
                ]
            }
        });

        return ticketSelections;
    }

    const createTicket = ({ validationOnly } = { validationOnly: false }) => {
        let ticketSelections = createTicketSelections();

        let ticket = {
            "metadata": validationOnly ? undefined : "",
            "selections": ticketSelections,
            "transaction": validationOnly ? undefined : {
                "transactionId": null,
                "metadata": "{}"
            },
            "stake": getEffectiveStake()
        };

        return ticket;
    }

    const stakeValid = () => {
        return getEffectiveStake() >= getMinimumStake() || getEffectiveStake()?.toFixed?.(2) === getMinimumStake()?.toFixed?.(2);
    }

    const payinEnabled = () => {
        console.log(stakeValid(), walletHasSufficientAmount(getEffectiveStake()), !loadingPayin, selections?.length > 0)
        return stakeValid() && walletHasSufficientAmount(getEffectiveStake()) && !loadingPayin && selections?.length > 0;
    }

    const updateRoundResult = (value) => {
        roundResultRef.current = value;
        setRoundResult(value);
    }

    const updateSimulationTicketIds = (value) => {
        simulationTicketIdsRef.current = value;
        setSimulationTicketIds(value);
    }

    const handlePayin = async () => {
        if (!payinEnabled()) {
            return;
        }
        setLoadingPayin(true);

        let transaction_uuid = uuidv4();
        let ticket = createTicket();

        ticket.transaction.transactionId = transaction_uuid;

        console.log("TICKET", ticket);

        let response = await axios.post(`/v1/ticket/create`, ticket, {
            headers: {
                "User-Id": userUuid.current,
                "OperId": "100",
                "Authorization": "testtest",
                "Platform": "android",
                "Session-Id": sessionId,
            }
        });

        console.log("TICKET RESPONSE", response);

        if (response?.status === 200) {
            let roundId = response?.data?.["roundId"];
            let ticketIds = response?.data?.["ticketIds"];
            await startSimulation(roundId, ticketIds);
        }
        
        setLoadingPayin(false);
    }

    const waitCountDown = async () =>{
        let countdown = 3;
        setSimulationCountdown(countdown);
        while (countdown > 0) {
            console.log("COUNTDOWN", countdown);
          await new Promise(resolve => setTimeout(resolve, 200));
          countdown--;
          setSimulationCountdown(countdown);
        }
    }

    const startSimulation = async (roundId, ticketIds) => {
        updateWallet(wallet - getEffectiveStake());
        
        if (roundId != null) {
            sessionStorage.setItem(SIMULATION_ROUND_ID_KEY, roundId);
        }
        if (ticketIds?.length > 0) {
            sessionStorage.setItem(SIMULATION_TICKET_IDS_KEY, JSON.stringify(ticketIds));
        }

        if(simulationCountdown == null){
            isReplayRef.current = false;
            await waitCountDown();
        }
        else {
            isReplayRef.current = true;
        }

        updateSimulationTicketIds(ticketIds);
        setSimulationRoundId(roundId);

        setShowTicket(false);
    }

    const replayTicket = async () => {
        if (roundResult != null) {
            setLiveScores({});
            liveScoreDisplayQueueRef.current = [];
            halfTimeExistsRef.current = false;
            setCurrentDisplayScore(null);
            setShowRoundResultDialog(false);
            updateRoundResult(null);
            await handlePayin();
        }
    }

    const addSelection = (selection) => {
        setSelections([...selections, selection]);
    }

    const removeSelection = (removedSelection) => {
        setSelections([...selections.filter(selection => JSON.stringify(selection) !== JSON.stringify(removedSelection))]);
    }

    const getSelectedTabEvents = () => {
        return events?.filter(event =>
            event?.status === (eventsTab === EVENTS_TAB_LIVE ? 1 : 0)
        );
    }

    const getSelectedEvents = () => {
        return events?.filter(event =>
            (event?.status === (eventsTab === EVENTS_TAB_LIVE ? 1 : 0))
            && (event?.markets?.some(market => market?.id === selectedMarket?.realSportMarketId))
        );
    }

    const getAvailableMarkets = () => {
        return markets?.filter(enabledMarket =>
            getSelectedTabEvents()?.some(event =>
                event?.markets?.some(eventMarket => eventMarket?.id === enabledMarket?.realSportMarketId)
            )
        );
    }

    const getRoundDuration = () => {
        return (generalConfig?.roundDuration) ?? 30000;
    }

    const skipSimulation = async (e, overrideRoundId) => {
        if (roundResult != null) {
            return;
        }

        // Fetch ticket result
        setLoadingRoundResult(true);
        setShowRoundResultDialog(true);

        console.log("overrideRoundId", overrideRoundId)
        console.log("simulationRoundId", simulationRoundId)
        console.log("overrideRoundId ?? simulationRoundId", overrideRoundId ?? simulationRoundId)

        let response = await axios.get(`/v1/ticket/result`, {
            headers: {
                "User-Id": userUuid.current,
                "Authorization": "",
                "Session-Id": sessionId,
            },
            params: {
                "roundId": overrideRoundId ?? simulationRoundId,
            }
        });

        console.log("ROUND RESULT", response?.data);

        let liveScoresUpdate = { ...liveScores };
        for (let ticket of response?.data) {
            for (let event of ticket?.events) {
                let resultsUpdate = [];
                let sortNumber = 1;
                let period = 1;
                let homeTeamScore = 0;
                let awayTeamScore = 0;
                for (let char of event?.resultSequence) {
                    switch (char) {
                        case "A":
                            homeTeamScore++;
                            resultsUpdate.push({
                                "awayTeamScore": false,
                                "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                                "homeTeamScore": true,
                                "message": "GOAL",
                                "period": period,
                                "sortNumber": sortNumber
                            });
                            break;
                        case "B":
                            awayTeamScore++;
                            resultsUpdate.push({
                                "awayTeamScore": true,
                                "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                                "homeTeamScore": false,
                                "message": "GOAL",
                                "period": period,
                                "sortNumber": sortNumber
                            });
                            break;
                        case "H":
                            period++;
                            resultsUpdate.push({
                                "awayTeamScore": false,
                                "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                                "homeTeamScore": false,
                                "message": "HT",
                                "period": period,
                                "sortNumber": sortNumber
                            });
                            break;
                    }
                    sortNumber++;
                }
                resultsUpdate.push({
                    "awayTeamScore": false,
                    "currentResult": `${homeTeamScore}-${awayTeamScore}`,
                    "homeTeamScore": false,
                    "message": "FT",
                    "period": period,
                    "sortNumber": sortNumber
                });
                liveScoresUpdate[`${ticket?.ticketId}__${event?.eventId}`] = {
                    "eventId": event?.eventId,
                    "ticketId": ticket?.ticketId
                }
                liveScoresUpdate[`${ticket?.ticketId}__${event?.eventId}`].results = resultsUpdate;
            }
        }
        setLiveScores(liveScoresUpdate);

        updateRoundResult(response?.data);

        liveScoreDisplayQueueRef.current = [];
        setCurrentDisplayScore(null);

        let totalReturn = response?.data?.[0]?.totalReturn;
        if (totalReturn > 0) {
            addWallet(totalReturn);
        }

        setLoadingRoundResult(false);
    }

    const finishSimulation = () => {
        setSelections([]);
        setLiveScores({});
        liveScoreDisplayQueueRef.current = [];
        halfTimeExistsRef.current = false;
        setSimulationCountdown(null);
        setCurrentDisplayScore(null);
        setShowRoundResultDialog(false);
        updateRoundResult(null);
        setShowTicket(false);
        updateSimulationTicketIds([]);
        setSimulationRoundId(null);
    }

    const setUserUuid = (uuid) => {
      userUuid.current = uuid;
    }

    const getTicketHistory = async () => {
      return await axios.get(`/v1/ticket/list`, {
        headers: {
          "User-Id": userUuid.current,
        }
      });
    }

    return {
        socketRef,
        roundResultRef,
        simulationTicketIdsRef,
        delayRef,
        isReplayRef,
        searchParams,
        setSearchParams,
        wallet,
        setWallet,
        sessionId,
        setSessionId,
        events,
        setEvents,
        eventsTab,
        setEventsTab,
        selectedMarket,
        setSelectedMarket,
        selectedEventList,
        setSelectedEventList,
        selectedEvent,
        setSelectedEvent,
        selections,
        setSelections,
        showTicket,
        setShowTicket,
        stake,
        setStake,
        simulationCountdown,
        setSimulationCountdown,
        simulationTicketIds,
        setSimulationTicketIds,
        showRoundResultDialog,
        setShowRoundResultDialog,
        simulationRoundId,
        setSimulationRoundId,
        generalConfig,
        setGeneralConfig,
        sports,
        setSports,
        markets,
        setMarkets,
        validationData,
        setValidationData,
        liveScores,
        setLiveScores,
        currentDisplayScore,
        setCurrentDisplayScore,
        roundResult,
        setRoundResult,
        loadingEvents,
        setLoadingEvents,
        loadingGeneralConfig,
        setLoadingGeneralConfig,
        loadingValidation,
        setLoadingValidation,
        loadingPayin,
        setLoadingPayin,
        loadingRoundResult,
        setLoadingRoundResult,
        addQueryParam,
        removeQueryParam,
        updateWallet,
        walletHasSufficientAmount,
        adjustWallet,
        addWallet,
        reduceWallet,
        getEvents,
        getGeneralConfig,
        validateSelections,
        getEffectiveStake,
        getMinimumStake,
        createTicketSelections,
        createTicket,
        stakeValid,
        payinEnabled,
        updateRoundResult,
        updateSimulationTicketIds,
        handlePayin,
        replayTicket,
        addSelection,
        removeSelection,
        getSelectedEvents,
        getAvailableMarkets,
        getRoundDuration,
        skipSimulation,
        finishSimulation,
        userUuid,
        setUserUuid,
        getTicketHistory,
    }

}

export const DataContext = createContext();

export const DataProvider = (props) => {

    const {
        socketRef,
        roundResultRef,
        simulationTicketIdsRef,
        delayRef,
        isReplayRef,
        searchParams,
        setSearchParams,
        wallet,
        setWallet,
        sessionId,
        setSessionId,
        events,
        setEvents,
        eventsTab,
        setEventsTab,
        selectedMarket,
        setSelectedMarket,
        selectedEventList,
        setSelectedEventList,
        selectedEvent,
        setSelectedEvent,
        selections,
        setSelections,
        showTicket,
        setShowTicket,
        stake,
        setStake,
        simulationCountdown,
        setSimulationCountdown,
        simulationTicketIds,
        setSimulationTicketIds,
        showRoundResultDialog,
        setShowRoundResultDialog,
        simulationRoundId,
        setSimulationRoundId,
        generalConfig,
        setGeneralConfig,
        sports,
        setSports,
        markets,
        setMarkets,
        validationData,
        setValidationData,
        liveScores,
        setLiveScores,
        currentDisplayScore,
        setCurrentDisplayScore,
        roundResult,
        setRoundResult,
        loadingEvents,
        setLoadingEvents,
        loadingGeneralConfig,
        setLoadingGeneralConfig,
        loadingValidation,
        setLoadingValidation,
        loadingPayin,
        setLoadingPayin,
        loadingRoundResult,
        setLoadingRoundResult,
        addQueryParam,
        removeQueryParam,
        updateWallet,
        walletHasSufficientAmount,
        adjustWallet,
        addWallet,
        reduceWallet,
        getEvents,
        getGeneralConfig,
        validateSelections,
        getEffectiveStake,
        getMinimumStake,
        createTicketSelections,
        createTicket,
        stakeValid,
        payinEnabled,
        updateRoundResult,
        updateSimulationTicketIds,
        handlePayin,
        replayTicket,
        addSelection,
        removeSelection,
        getSelectedEvents,
        getAvailableMarkets,
        getRoundDuration,
        skipSimulation,
        finishSimulation,
        userUuid,
        setUserUuid,
        getTicketHistory,
    } = useProviderFunctions()

    return (
        <DataContext.Provider value={
            {
                userUuid,
                setUserUuid,
                socketRef,
                roundResultRef,
                simulationTicketIdsRef,
                delayRef,
                isReplayRef,
                searchParams,
                setSearchParams,
                wallet,
                setWallet,
                sessionId,
                setSessionId,
                events,
                setEvents,
                eventsTab,
                setEventsTab,
                selectedMarket,
                setSelectedMarket,
                selectedEventList,
                setSelectedEventList,
                selectedEvent,
                setSelectedEvent,
                selections,
                setSelections,
                showTicket,
                setShowTicket,
                stake,
                setStake,
                simulationCountdown,
                setSimulationCountdown,
                simulationTicketIds,
                setSimulationTicketIds,
                showRoundResultDialog,
                setShowRoundResultDialog,
                simulationRoundId,
                setSimulationRoundId,
                generalConfig,
                setGeneralConfig,
                sports,
                setSports,
                markets,
                setMarkets,
                validationData,
                setValidationData,
                liveScores,
                setLiveScores,
                currentDisplayScore,
                setCurrentDisplayScore,
                roundResult,
                setRoundResult,
                loadingEvents,
                setLoadingEvents,
                loadingGeneralConfig,
                setLoadingGeneralConfig,
                loadingValidation,
                setLoadingValidation,
                loadingPayin,
                setLoadingPayin,
                loadingRoundResult,
                setLoadingRoundResult,
                addQueryParam,
                removeQueryParam,
                updateWallet,
                walletHasSufficientAmount,
                adjustWallet,
                addWallet,
                reduceWallet,
                getEvents,
                getGeneralConfig,
                validateSelections,
                getEffectiveStake,
                getMinimumStake,
                createTicketSelections,
                createTicket,
                stakeValid,
                payinEnabled,
                updateRoundResult,
                updateSimulationTicketIds,
                handlePayin,
                replayTicket,
                addSelection,
                removeSelection,
                getSelectedEvents,
                getAvailableMarkets,
                getRoundDuration,
                skipSimulation,
                finishSimulation,
                getTicketHistory,
            }
        }>
            {props.children}
        </DataContext.Provider>
    );
}