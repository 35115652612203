import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataContext } from '../Context';

const ProgressTimer = () => {
    const context = useContext(DataContext);
    const roundResultRef = useRef(context?.roundResult);
    const timerTextRef = useRef(null);
    const timerMinutesRef = useRef(null);
    const [timerText, setTimerText] = useState("1st half");
    const [timerMinutes, setTimerMinutes] = useState(0);

    useEffect(() => {
        roundResultRef.current = context?.roundResult;
    }, [context?.roundResult, context?.simulationRoundId]);

    useEffect(() => {
        let startTime = Date.now();
        if(context?.isReplayRef.current !== true){
            // Adjust start time based on countdown duration;
            startTime -= 200 * 3;
        }
        timerTextRef.current = getTimerText(0);
        timerMinutesRef.current = getTimerMinutes(0);

        const updateProgress = () => {
            if (roundResultRef.current != null) {
                timerTextRef.current = getTimerText(100);
                timerMinutesRef.current = getTimerMinutes(100);
    
                setTimerText(timerTextRef.current);
                setTimerMinutes(timerMinutesRef.current);
                return;
            }

            const elapsedTime = Date.now() - startTime;
            const progressPercentage = Math.min((elapsedTime / context?.getRoundDuration()) * 100, 100);

            timerTextRef.current = getTimerText(progressPercentage);
            timerMinutesRef.current = getTimerMinutes(progressPercentage);

            setTimerText(timerTextRef.current);
            setTimerMinutes(timerMinutesRef.current);

            if (progressPercentage < 100 && roundResultRef.current == null) {
                requestAnimationFrame(updateProgress);
            }
        };

        requestAnimationFrame(updateProgress);
    }, [context?.simulationRoundId]);

    function getTimerText(percentage){
        if(percentage < 50){
            return "1st half";
        }
        else if(percentage < 100){
            return "2nd half";
        }
        return "Full time";
    }

    function getTimerMinutes(percentage){
        return Math.floor(Math.min(90, Math.max(0, (percentage / 100) * 90)));
    }

    return (
        <div className="ProgressTimer">
            {timerText}<b>{timerMinutes?.toString()?.padStart(2, '0')}'</b>
        </div>
    );
};

export default ProgressTimer;
