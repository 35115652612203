import FOOTBALL from '../assets/images/football.svg';
import CLOSE from '../assets/images/close.svg';
import LIVE from '../assets/images/live.svg';
import { useContext } from 'react';
import { DataContext } from '../Context';
import formatTime from '../utils/format_time';


function MatchSelection({ event, selection }) {
    const context = useContext(DataContext);

    const market = event?.markets?.find(market => market?.id === selection?.market_id);
    const outcome = market?.outcomes?.find(outcome => outcome?.id === selection?.outcome_id);

    return (
        <div className="MatchSelection">
            <div className="MatchSelection-header">
                <p><img className='football-icon' src={FOOTBALL} />{formatTime(new Date(event?.estimateStartTime))}</p>
                {
                    event?.status === 0 ? null :
                        <img className='live-icon' src={LIVE} />
                }
                <div className="MatchSelection-remove" onClick={() => { context?.removeSelection(selection) }}>
                    <img className='close-icon' src={CLOSE} />
                </div>
            </div>
            <div className="MatchSelection-info">
                <div className="MatchSelection-match">
                    <p className="MatchRow-team-name">{event?.homeTeamName}</p>
                    <p className="MatchRow-team-name">{event?.awayTeamName}</p>
                </div>
                <div className="MatchSelection-outcome">
                    {outcome?.desc}
                </div>
            </div>
            <div className="MatchSelection-bet">
                <div className="MatchSelection-market">
                    {market?.name}
                    {
                        selection?.specifier?.length > 0 ? <span> ({selection?.specifier})</span> : null
                    }
                </div>
                <div className="MatchSelection-odd">
                    {outcome?.odds}
                </div>
            </div>
        </div>
    );
}

export default MatchSelection;
