// react imports
import { useContext } from 'react';
import { DataContext } from '../Context';
// components
import Betslip from '../components/Betslip';
import MatchList from '../components/MatchList';
import SimulationDialog from '../components/SimulationDialog';
import AppHeader from '../components/AppHeader';
import Banner from '../components/Banner';
import BottomBar from '../components/BottomBar';
import RoundResultDialog from '../components/RoundResultDialog';
import EventTabs from '../components/EventTabs';
import MarketChips from '../components/MarketChips';
import EventDetails from '../components/EventDetails';
import SimulationCountdown from '../components/SimulationCountdown';

function SportsPage() {
  const context = useContext(DataContext);

  return (
    <>
      <div className="App">
        <div className="AppHeader">
          <AppHeader />
          <Banner />
          <EventTabs />
          <MarketChips />
        </div>
        <MatchList />
        {context?.selectedEvent ? <EventDetails /> : null}
        <BottomBar />
        {context?.showTicket ? <Betslip /> : null}
      </div>
      {context?.simulationCountdown != null ? <SimulationCountdown /> : null}
      {context?.simulationRoundId != null ? <SimulationDialog /> : null}
      {context?.roundResult != null && context?.showRoundResultDialog ? <RoundResultDialog /> : null}
    </>
  )
}

export default SportsPage;
