
import LOGO from '../assets/images/logo.svg';
import PROFILE from '../assets/images/profile.svg';
import WALLET from '../assets/images/wallet 1.svg';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { DataContext } from "../Context";

function AppHeader() {
  const context = useContext(DataContext);
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/');
  }

  return (
      <div className="AppHeader-header">
          {/* <div className="AppHeader-header-menu">
              <img src={MENU} alt='menu' />
          </div> */}
          <div className="AppHeader-header-logo" onClick={goToHomePage}>
              <img src={LOGO} alt='logo' height={36} />
          </div>
          <div className="AppHeader-header-profile">
              <div className='AppHeader-header-profile__funds'>
                <div>Funds available:</div>
                <div className='AppHeader-header-profile__wallet'>
                  <img src={WALLET} alt='wallet' />
                  <div>{parseFloat(context?.wallet ?? 0)?.toFixed(2)}</div>
                </div>
              </div>
              <img src={PROFILE} alt='profile' />
          </div>
      </div>
  );
}

export default AppHeader;
