import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../Context";
import BACK from '../assets/images/back.svg';
import TICKET_HISTORY from '../assets/images/ticket-history.svg';
import FLASH from '../assets/images/flash.svg';
import AppHeader from "../components/AppHeader";

function TicketsPage() {
  const context = useContext(DataContext);
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    setTimeout(getTickets, 0);
  }, []);

  const getTickets = async () => {
    const resp = await context.getTicketHistory()
    setTickets(resp.data.data);
  }

  const closeTicket = () => {
    navigate('/');
  }

  const navitateToTicketHistory = () => {
    navigate('/tickets')
  }

  return (
    <div style={{
      height: '100dvh',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <AppHeader />

      <main
        style={{
          flex: 1
        }}
        className="Tickets-wrapper"
      >
        {/* Actions */}
        <div className="Betslip-actions">
            <div
                className="Betslip-return"
                onClick={closeTicket}
            >
                <img src={BACK} alt="Back" height="20" width="20" />
            </div>
            <div className="Betslip-action-items">
                <div className="Betslip-action-tickets" onClick={navitateToTicketHistory}>
                    <img src={TICKET_HISTORY} alt="Back" height="20" width="20" />
                    <span>Tickets</span>
                </div>
                <div className="Betslip-action-flash">
                    <span>Flash</span>
                    <img src={FLASH} alt="Back" height="20" width="20" />
                </div>
            </div>
        </div>

        {/* Tickets container */}
        <div className="Tickets-wrapper__tickets">
          <h2>Tickets</h2>
          <div className="Tickets-wrapper__tickets__list">
            {
              tickets.map(ticket =>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '50px',
                    padding: '7px 16px',
                    borderRadius: '8px',
                    borderWidth: '1px',
                    borderColor: ticket.status === 'Won' ? '#02A752' : '#EE2830',
                    borderStyle: 'solid',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                  key={ticket.ticketId}
                >
                  <div>
                    <div>Ticket ID</div>
                    <div style={{ fontWeight: '600' }}>{ ticket.ticketId }</div>
                  </div>
                  <div>
                    <div>Selections</div>
                    <div style={{ fontWeight: '600' }}>{ ticket.events.length }</div>
                  </div>
                  <div>
                    <div>Bet</div>
                    <div style={{ fontWeight: '600' }}>{ ticket.stake }</div>
                  </div>
                  <div>
                    <div>Won</div>
                    <div style={{ fontWeight: '600' }}>{ ticket.totalReturn }</div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </main>
    </div>
  );
}

export default TicketsPage;
