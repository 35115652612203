import { useContext, useEffect, useRef, useState } from "react";
import { DataContext } from "../Context";
import SIM_ANIMATION from '../assets/images/sim_animation.gif';
import SIM_ANIMATION_EXTENDED from '../assets/images/sim_animation_extended.gif';
import SIM_ANIMATION_END from '../assets/images/sim_animation_end.jpg';
import SIM_ANIMATION_END_EXTENDED from '../assets/images/sim_animation_end_extended.jpg';
import SIMULATION_FLASH from '../assets/images/simulation-flash.gif';
import EXTEND_SIMULATION from '../assets/images/extend-simulation.svg';
import FOOTBALL_GOAL_SPARK from '../assets/images/football-goal-spark.gif';
import FOOTBALL_SPIN from '../assets/images/football-spin.gif';
import REPLAY_TICKET from '../assets/images/replay-ticket.svg';
import LoadingBar from 'react-top-loading-bar'
import ProgressBar from "./ProgressBar";
import MatchSelection from "./MatchSelection";
import SimulationMatch from "./SimulationMatch";
import ProgressTimer from "./ProgressTimer";
import BIG_VIDEO_HALFTIME from '../assets/videos/big_halftime.mp4';
import BIG_VIDEO_DEFEND_BLUE from '../assets/videos/big_defend_blue.mp4';
import BIG_VIDEO_ATTACK_BLUE from '../assets/videos/big_attack_blue.mp4';
import BIG_VIDEO_DEFEND_RED from '../assets/videos/big_defend_red.mp4';
import BIG_VIDEO_ATTACK_RED from '../assets/videos/big_attack_red.mp4';
import SMALL_VIDEO_HALFTIME from '../assets/videos/small_halftime.mp4';
import SMALL_VIDEO_DEFEND_BLUE from '../assets/videos/small_defend_blue.mp4';
import SMALL_VIDEO_ATTACK_BLUE from '../assets/videos/small_attack_blue.mp4';
import SMALL_VIDEO_DEFEND_RED from '../assets/videos/small_defend_red.mp4';
import SMALL_VIDEO_ATTACK_RED from '../assets/videos/small_attack_red.mp4';

import BIG_VIDEO_HALFTIME_LOOP from '../assets/videos/big_halftime_loop.mp4';
import SMALL_VIDEO_HALFTIME_LOOP from '../assets/videos/small_halftime_loop.mp4';

const { REACT_APP_FLASHBET_BACKEND_BASE_URL } = process.env;

function SimulationDialog() {
    const context = useContext(DataContext);

    const videoRef = useRef(null);
    const endVideoRef = useRef(null);

    const smallVideosRef = useRef([
        SMALL_VIDEO_HALFTIME,
        SMALL_VIDEO_DEFEND_BLUE,
        SMALL_VIDEO_ATTACK_BLUE,
        SMALL_VIDEO_DEFEND_RED,
        SMALL_VIDEO_ATTACK_RED,
    ]);
    const bigVideosRef = useRef([
        BIG_VIDEO_HALFTIME,
        BIG_VIDEO_DEFEND_BLUE,
        BIG_VIDEO_ATTACK_BLUE,
        BIG_VIDEO_DEFEND_RED,
        BIG_VIDEO_ATTACK_RED,
    ]);

    const currentVideoIndexRef = useRef(0);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [extendAnimation, setExtendAnimation] = useState(context?.selections?.length > 1 ? false : true);

    useEffect(() => {
        let interval = setInterval(()=>{
            currentVideoIndexRef.current = Math.floor(Math.random() * smallVideosRef.current.length);
        }, 2000)

        return ()=>{
            clearInterval(interval);
        }
    },[]);

    useEffect(() => {
        setCurrentVideoIndex(currentVideoIndexRef.current);
    },[currentVideoIndexRef.current, context?.currentDisplayScore]);

    // For now we assume one ticket, but after autobet is added this logic must be changed
    const getTicketId = () => {
        return context?.simulationTicketIds?.[0];
    }

    const toggleExtendAnimation = () => {
        setExtendAnimation(!extendAnimation);
    }

    const getLiveScoreEventType = () => {
        switch (context?.currentDisplayScore?.currentResult?.message) {
            case "GOAL":
                let homeOrAway = context?.currentDisplayScore?.currentResult?.homeTeamScore ? "home" : "away";
                return `goal-${homeOrAway}`;
            case "HT":
                return "halftime";
            case "FT":
                return "fulltime";
        }
        return ""
    }

    const getLiveScoreEventMessage = () => {
        console.log("CURRENT SIM DISPLAY", structuredClone(context?.currentDisplayScore));
        switch (context?.currentDisplayScore?.currentResult?.message) {
            case "GOAL":
                return "GOAL !";
            case "HT":
                return "Half time";
            case "FT":
                return "Full time";
        }
        return ""
    }

    const getLiveScoreTeamShortName = (name) => {
        if (name?.includes(" ")) {
            name = name?.split(" ")?.map(namePart => namePart?.length > 3 ? `${namePart?.substring(0, 3)}.` : namePart)?.slice(0, 2)?.join(" ")
        }
        return name
    }

    const getLiveScoreHomeTeamShortName = () => {
        let event = context?.events?.find(event => event?.eventId === context?.currentDisplayScore?.eventId);
        return getLiveScoreTeamShortName(event?.homeTeamName);
    }

    const getLiveScoreAwayTeamShortName = () => {
        let event = context?.events?.find(event => event?.eventId === context?.currentDisplayScore?.eventId);
        return getLiveScoreTeamShortName(event?.awayTeamName);
    }

    console.log(currentVideoIndexRef.current);
    console.log((extendAnimation ? bigVideosRef : smallVideosRef).current[currentVideoIndexRef.current]);

    return (
        <div className="SimulationDialog">
        <img className="SimulationDialog-flash" src={SIMULATION_FLASH} />
            <div className="SimulationDialogContent">
                <div className={`SimulationDialogContent-animation ${extendAnimation ? "SimulationDialogContent-animation-extended" : ""}`}>
                    {
                        context?.roundResult == null ? <>
                            {/*<img src={SIM_ANIMATION_EXTENDED} />*/}
                            <video 
                                ref={videoRef}
                                autoPlay
                                loop
                                muted
                                playsInline
                                controls=""
                                poster=""
                                onCanPlay={() => videoRef.current.play()}
                                onLoadedMetadata={() => (videoRef.current.muted = true)}
                                key={`video-source-${(extendAnimation ? bigVideosRef : smallVideosRef).current[currentVideoIndexRef.current]}`}
                            >
                                <source src={(extendAnimation ? bigVideosRef : smallVideosRef).current[currentVideoIndexRef.current]} type="video/mp4" />
                            </video>
                            {
                                context?.currentDisplayScore != null ?
                                    <div className={`SimulationDialogContent-current-display ${extendAnimation ? `SimulationDialogContent-current-display-extended` : ``}`}>
                                        {
                                            getLiveScoreEventType()?.startsWith('goal-') ?
                                                <div className={`SimulationDialogContent-current-display-goal-animation goal-animation-${getLiveScoreEventType()}`} key={`ball-animation-${context?.currentDisplayScore?.eventId}__${context?.currentDisplayScore?.currentResult?.currentResult}`}>
                                                    <img className="goal-animation-spark-long" src={FOOTBALL_GOAL_SPARK} />
                                                    <img className="goal-animation-spark" src={FOOTBALL_GOAL_SPARK} />
                                                    <img className="goal-animation-ball" src={FOOTBALL_SPIN} />
                                                    <div className="goal-animation-ball-shine"></div>
                                                </div>
                                                : null
                                        }
                                        <div className={`SimulationDialogContent-current-display-event live-score-event-${getLiveScoreEventType()}`} key={`live-display-${context?.currentDisplayScore?.eventId}__${context?.currentDisplayScore?.currentResult?.currentResult}`}>
                                            <div className="SimulationDialogContent-current-display-message">
                                                {getLiveScoreEventMessage()}
                                            </div>
                                            {
                                                context?.currentDisplayScore?.currentResult?.messageOnly === true ? null :
                                                    <div className="SimulationDialogContent-current-display-teams">
                                                        <div className="SimulationDialogContent-current-display-team SimulationDialogContent-current-display-team-home">
                                                            {getLiveScoreHomeTeamShortName()?.padStart(15, " ")}
                                                        </div>
                                                        <div className="SimulationDialogContent-current-display-score">
                                                            {context?.currentDisplayScore?.currentResult?.currentResult}
                                                        </div>
                                                        <div className="SimulationDialogContent-current-display-team SimulationDialogContent-current-display-team-away">
                                                            {getLiveScoreAwayTeamShortName()?.padEnd(15, " ")}
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div> : null
                            }
                        </>
                            : <>
                                {/*<img src={SIM_ANIMATION_END_EXTENDED} />*/}
                                <video 
                                    ref={endVideoRef}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    controls=""
                                    poster=""
                                    onCanPlay={() => endVideoRef.current.play()}
                                    onLoadedMetadata={() => (endVideoRef.current.muted = true)}
                                >
                                    <source src={extendAnimation ? BIG_VIDEO_HALFTIME_LOOP : SMALL_VIDEO_HALFTIME_LOOP} type="video/mp4" />
                                </video>
                                <div className="SimulationDialogContent-round-end">
                                    ROUND ENDED
                                </div>
                            </>
                    }
                </div>
                <ProgressBar />
                <div className="SimulationDialogBelowProgress">
                    <img className={`SimulationDialog-extend-simulation-btn ${extendAnimation ? "SimulationDialog-extend-simulation-btn-active" : ""}`} onClick={toggleExtendAnimation} src={EXTEND_SIMULATION} />
                    <ProgressTimer />
                </div>
                <table className="SimulationMatchList">
                    <tbody>
                        {
                            context?.selections.map((selection, selection_index) => {
                                let event = context?.events?.find(event => event?.eventId === selection?.event_id);
                                let liveScore = context?.liveScores?.[`${getTicketId()}__${event?.eventId}`];
                                return <SimulationMatch
                                    event={event}
                                    selection={selection}
                                    liveScore={liveScore}
                                    number={selection_index + 1}
                                    key={selection_index} />;
                            }

                            )
                        }
                    </tbody>
                </table>
                <div className="spacer"></div>
                {context?.roundResult == null ? null : <div className={`SimulationDialogContent-replay-btn`} onClick={context?.replayTicket}><img src={REPLAY_TICKET} /> REPLAY TICKET <img src={REPLAY_TICKET} /></div>}
                <div className={`SimulationDialogContent-skip-btn`} onClick={context?.roundResult == null ? context?.skipSimulation : context?.finishSimulation}>
                    {context?.roundResult == null ? "SKIP" : "FINISH"}
                </div>
            </div>
            {/*<div className="focus"></div>*/}
        </div>
    );
}

export default SimulationDialog;
