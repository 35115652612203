
const EVENTS_TAB_LIVE = "LIVE";
const EVENTS_TAB_PREMATCH = "PREMATCH";
const WALLET_KEY = "wallet";
const AUTHENTICATED_KEY = "isAuthenticated";
const USER_UUID_KEY = 'flashbet:userUuid';
const SIMULATION_ROUND_ID_KEY = "simulationRoundId";
const SIMULATION_TICKET_IDS_KEY = "simulationTicketIds";
const MIN_RESULT_DISPLAY_DELAY = 500;
const MAX_RESULT_DISPLAY_DELAY = 3000;
const ROUND_RESULT_DIALOG_CONTENT_ID = "RoundResultDialogContentID";
const events_tabs = [
    EVENTS_TAB_LIVE,
    EVENTS_TAB_PREMATCH,
];

// QUERY PARAM KEYS
const QUERY_KEY_EVENTS_TAB = "events_tab";
const QUERY_KEY_SELECTED_MARKET_ID = "selected_market_id";
const QUERY_KEY_SELECTED_EVENT_ID = "selected_event_id";
const QUERY_KEY_SHOW_TICKET = "show_ticket";


module.exports = {
    EVENTS_TAB_LIVE,
    EVENTS_TAB_PREMATCH,
    WALLET_KEY,
    AUTHENTICATED_KEY,
    USER_UUID_KEY,
    SIMULATION_ROUND_ID_KEY,
    SIMULATION_TICKET_IDS_KEY,
    MIN_RESULT_DISPLAY_DELAY,
    MAX_RESULT_DISPLAY_DELAY,
    ROUND_RESULT_DIALOG_CONTENT_ID,
    events_tabs,
    QUERY_KEY_EVENTS_TAB,
    QUERY_KEY_SELECTED_MARKET_ID,
    QUERY_KEY_SELECTED_EVENT_ID,
    QUERY_KEY_SHOW_TICKET,
};