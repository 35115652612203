
function Loader({ small, color, noMargin }) {
    const style = color != null ? {
        background: `radial-gradient(farthest-side, ${color} 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, ${color})`
    } : null;

    return (
        <div className={`loader ${small ? "small" : ""} ${noMargin ? "no-margin" : ""}`} style={style} />
    );
}

export default Loader;
