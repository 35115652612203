import { useEffect } from "react";
import { ROUND_RESULT_DIALOG_CONTENT_ID } from "../constants";

function ElectricSpark({ position, elementId }) {

    useEffect(() => {
        const sparkCenter = document.getElementById(elementId);

        let positionOffset = 0;
        let positionVertical = position?.includes("top") ? "top" : "bottom";
        let positionHorizontal = position?.includes("left") ? "left" : "right";
        let pathHeightBaseOffset = 70;
        switch (positionVertical) {
            case "top":
                sparkCenter.style.top = `${positionOffset}px`;
                break;
            case "bottom":
                sparkCenter.style.bottom = `${positionOffset}px`;
                pathHeightBaseOffset = 0;
                break;
        }
        switch (positionHorizontal) {
            case "left":
                sparkCenter.style.left = `${positionOffset}px`;
                break;
            case "right":
                sparkCenter.style.right = `${positionOffset}px`;
                break;
        }

        function createSpark() {
            const spark = document.createElement('div');
            spark.classList.add('spark');

            // Random angle and distance for sparks
            const angle = Math.random() * 360;
            const distance = Math.random() * 100 + 50;
            const pathHeightOffset = Math.random() * 100 + pathHeightBaseOffset;

            const x = Math.cos(angle) * distance;
            const y = Math.sin(angle) * distance - (pathHeightBaseOffset - 40);

            // Parabolic path using quadratic bezier curve
            const controlX = x / 2;
            const controlY = y - pathHeightOffset;  // Height of the arc
            const endX = x;
            const endY = y;

            const path = `M 0,0 Q ${controlX},${controlY} ${endX},${endY}`;

            // Assign motion path
            spark.style.offsetPath = `path('${path}')`;

            sparkCenter.appendChild(spark);

            // Remove spark after animation is done
            setTimeout(() => {
                spark.remove();
            }, 1200);
        }

        function createSparks() {
            // Create multiple sparks
            for (let i = 0; i < 10; i++) {
                createSpark();
            }
        }

        // Continuously create sparks at random intervals
        let interval = setInterval(createSparks, 100);

        let timeout = setTimeout(() => {
            sparkCenter.remove();
        }, 3000);

        return () => {
            clearTimeout(timeout);
            clearInterval(interval);
        }

    }, []);

    return (
        <div id={elementId} className="spark-center"></div>
    );
}

export default ElectricSpark;
