import { useEffect } from "react";
import { ROUND_RESULT_DIALOG_CONTENT_ID } from "../constants";

function ElectricLine({ position, elementId }) {

    useEffect(() => {
        let opts = {
            lineWidth: 4,
            numberOfPoints: 20,
            amplitude: 15,
            spacing: 10,
            margin: 10,
            // fixedWidth: 300
        }
        let coloredOpts = {
            lineWidth: 2,
            numberOfPoints: 20,
            amplitude: 8,
            spacing: 10,
            margin: 6,
            // fixedWidth: 300
        }

        // Set up SVG box
        let container = document.getElementById(ROUND_RESULT_DIALOG_CONTENT_ID);
        let electricLine = document.getElementById(elementId)
        let height = opts.amplitude + opts.margin * 2
        electricLine.style.height = height + "px"

        let positionOffset = -17;
        if (position === "top") {
            electricLine.style.top = `${positionOffset}px`;
        }
        else {
            electricLine.style.bottom = `${positionOffset}px`;
        }

        // Generate coordinates
        let points = []
        for (let i = opts.numberOfPoints; i--;)
            points.push(i)

        let animate = () => {

            let width = container.clientWidth;
            electricLine.style.width = width + "px"

            let coords = points.map(n => {
                let first = n == 0
                let last = n == opts.numberOfPoints - 1
                let x = (width - opts.margin * 2) / (opts.numberOfPoints - 1) * n + opts.margin
                let y = (first || last) ? height / 2 : (height - opts.amplitude) / 2 + Math.random() * opts.amplitude

                return { x, y }
            })
            let coloredCoords = points.map(n => {
                let first = n == 0
                let last = n == coloredOpts.numberOfPoints - 1
                let x = (width - coloredOpts.margin * 2) / (coloredOpts.numberOfPoints - 1) * n + coloredOpts.margin
                let y = (first || last) ? height / 2 : (height - coloredOpts.amplitude) / 2 + Math.random() * coloredOpts.amplitude

                return { x, y }
            })

            // Draw path
            let path = electricLine.querySelector(`#${elementId} path.main-path`)
            if (path != null) {
                path.setAttribute("d", "M" + coords.map(coord => coord.x + "," + coord.y).join(" L"))

                // Style path
                let deviation = Math.random() * (5 - 2) + 2
                path.style.opacity = deviation / 5 + 0.2
                path.style.strokeWidth = opts.lineWidth

                // Style glow
                let glow = electricLine.querySelector(`#${elementId} #glow feDropShadow`)
                if (glow != null) {
                    glow.setAttribute('stdDeviation', deviation)
                }
            }
            // Draw colored path
            let coloredPath = electricLine.querySelector(`#${elementId} path.colored-path`)
            if (coloredPath != null) {
                coloredPath.setAttribute("d", "M" + coloredCoords.map(coord => coord.x + "," + coord.y).join(" L"))

                // Style coloredPath
                let deviation = Math.random() * (5 - 2) + 2
                coloredPath.style.opacity = deviation / 5 + 0.2
                coloredPath.style.strokeWidth = coloredOpts.lineWidth
            }

            // Loop
            requestAnimationFrame(animate)

        }

        // Initiate animation
        requestAnimationFrame(animate)

        let timeout = setTimeout(() => {
            electricLine.remove();
        }, 3000);

        return () => {
            clearTimeout(timeout);
        }
    }, []);

    return (
        <svg id={elementId} className="electric-line">
            <defs>
                <filter id="glow" x="-100%" y="-100%" width="300%" height="300%">
                    <feDropShadow dx="0" dy="0" stdDeviation="3"></feDropShadow>
                </filter>
            </defs>
            <path className="colored-path" style={{ filter: "url(#glow)" }} d="M10,0 L100,0"></path>
            <path className="main-path" style={{ filter: "url(#glow)" }} d="M10,0 L100,0"></path>
        </svg>
    );
}

export default ElectricLine;
