import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { DataProvider } from './Context';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import SportsPage from './routes/SportsPage';
import TicketsPage from './routes/TicketsPage';

const router = createHashRouter([
  {
    path: "/",
    element: <DataProvider>
      <App />
    </DataProvider>,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <SportsPage />
      },
      {
        path: "/tickets",
        element: <TicketsPage />,
        errorElement: <ErrorPage />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
