import { useEffect, useRef } from "react";
import { ROUND_RESULT_DIALOG_CONTENT_ID } from "../constants";

function ElectricLoader({ elementId, radius }) {

    const basicLoaderRef = useRef(null);
    const loaderHeadRef = useRef(null);
    const loaderHeadWhiteRef = useRef(null);

    useEffect(() => {
        radius = radius ?? 60

        let opts = {
            lineWidth: Math.max(3 * (Math.round(radius / 60)), 1),
            numberOfPoints: 20,
            amplitude: Math.max(15 * (Math.round(radius / 60)), 4),
        }
        let coloredOpts = {
            lineWidth: Math.max(1 * (Math.round(radius / 60)), 1),
            numberOfPoints: 20,
            amplitude: Math.max(8 * (Math.round(radius / 60)), 2),
        }

        let basicLoaderLineWidth = Math.max(opts.lineWidth * 2, 6);

        if (basicLoaderRef.current != null) {
            basicLoaderRef.current.style.background = `radial-gradient(farthest-side, var(--secondary) 94%, #0000) top/${basicLoaderLineWidth}px ${basicLoaderLineWidth}px no-repeat, conic-gradient(#0000 30%, var(--secondary))`;
            basicLoaderRef.current.style.webkitMask = `radial-gradient(farthest-side, #0000 calc(100% - ${basicLoaderLineWidth}px), #000 0)`
        }
        if (loaderHeadRef.current != null) {
            loaderHeadRef.current.style.width = `${Math.max(12 * (radius / 40), 8)}px`;
        }
        if (loaderHeadWhiteRef.current != null) {
            loaderHeadWhiteRef.current.style.width = `${Math.max(8 * (radius / 40), 6)}px`;
            loaderHeadWhiteRef.current.style.top = `${Math.max(2 * (radius / 40), 0)}px`;
        }

        // Set up SVG box
        let electricLoader = document.getElementById(elementId)
        let size = `${radius * 2}px`;
        electricLoader.style.width = size;
        electricLoader.style.height = size;
        electricLoader.parentElement.style.width = size;
        electricLoader.parentElement.style.minWidth = size;
        electricLoader.parentElement.style.minHeight = size;
        let centerX = radius + opts.lineWidth / 2;
        let centerY = radius + opts.lineWidth / 2;
        let coloredCenterX = radius + coloredOpts.lineWidth / 2;
        let coloredCenterY = radius + coloredOpts.lineWidth / 2;

        // Generate coordinates
        let points = []
        for (let i = opts.numberOfPoints; i--;)
            points.push(i)

        // Add additional point to close circle
        points.push(-1)

        let animate = () => {

            if (electricLoader == null) {
                requestAnimationFrame(animate)
                return;
            }

            let coords = points.map(n => {
                let angle = 0.53 * Math.PI + (1 * Math.PI / opts.numberOfPoints) * n; // Divide the circle into equal angles
                let dampFactor = Math.max(0.1 * (points.length - n), 1);
                let x = centerX + Math.cos(angle) * (radius - opts.amplitude / 2 + (Math.random() * opts.amplitude / 2) / dampFactor);  // Convert polar to Cartesian for x
                let y = centerY + Math.sin(angle) * (radius - opts.amplitude / 2 + (Math.random() * opts.amplitude / 2) / dampFactor); // Convert polar to Cartesian for y

                return { x, y };
            });
            let coloredCoords = points.map(n => {
                let angle = 0.53 * Math.PI + (1 * Math.PI / opts.numberOfPoints) * n; // Divide the circle into equal angles
                let dampFactor = Math.max(0.1 * (points.length - n), 1);
                let x = coloredCenterX + Math.cos(angle) * (radius - (opts.amplitude - opts.lineWidth) / 2 + (Math.random() * coloredOpts.amplitude / 2) / dampFactor);  // Convert polar to Cartesian for x
                let y = coloredCenterY + Math.sin(angle) * (radius - (opts.amplitude - opts.lineWidth) / 2 + (Math.random() * coloredOpts.amplitude / 2) / dampFactor); // Convert polar to Cartesian for y

                return { x, y }
            })

            // Draw path
            let path = electricLoader.querySelector(`#${elementId} path.main-path`)
            if (path != null) {
                path.setAttribute("d", "M" + coords.map(coord => coord.x + "," + coord.y).join(" L"))

                // Style path
                let deviation = Math.random() * (5 - 2) + 2
                path.style.opacity = deviation / 5 + 0.2
                path.style.strokeWidth = opts.lineWidth

                // Style glow
                let glow = electricLoader.querySelector(`#${elementId} #glow feDropShadow`)
                if (glow != null) {
                    glow.setAttribute('stdDeviation', deviation)
                }
            }
            // Draw colored path
            let coloredPath = electricLoader.querySelector(`#${elementId} path.colored-path`)
            if (coloredPath != null) {
                coloredPath.setAttribute("d", "M" + coloredCoords.map(coord => coord.x + "," + coord.y).join(" L"))

                // Style coloredPath
                let deviation = Math.random() * (5 - 2) + 2
                coloredPath.style.opacity = deviation / 5 + 0.2
                coloredPath.style.strokeWidth = coloredOpts.lineWidth
            }

            // Loop
            requestAnimationFrame(animate)

        }

        // Initiate animation
        requestAnimationFrame(animate)

        return () => {
        }
    }, [radius]);

    return (
        <div className="ElectricLoader">
            <div ref={basicLoaderRef} className={`electric-loader-basic`} />
            <div ref={loaderHeadRef} className={`electric-loader-head`} />
            <div ref={loaderHeadWhiteRef} className={`electric-loader-head-white`} />
            <svg id={elementId} className="electric-loader">
                <defs>
                    <filter id="glow" x="-100%" y="-100%" width="300%" height="300%">
                        <feDropShadow dx="0" dy="0" stdDeviation="3"></feDropShadow>
                    </filter>
                </defs>
                <path className="colored-path" style={{ filter: "url(#glow)" }} d="M10,0 L100,0"></path>
                <path className="main-path" style={{ filter: "url(#glow)" }} d="M10,0 L100,0"></path>
            </svg>
        </div>
    );
}

export default ElectricLoader;
